
import { Vue, Component, Prop } from 'vue-property-decorator';
import { ArrowRightIcon, ArrowLeftIcon } from 'vue-feather-icons';
import pdf from 'vue-pdf';

@Component({
  components: {
    pdf,
    ArrowRightIcon,
    ArrowLeftIcon,
  },
})
export default class PdfViewer extends Vue {
  @Prop() url!: string;

  @Prop(String) title!: string;

  @Prop(Boolean) model!: boolean;

  currentPage = 0;

  pageCount = 0;

  page = 1;

  loadedRatio = 0;

  numPages = 0;

  documentCheckbox = false;

  get active() {
    return this.model;
  }

  set active(_value) {
    this.$emit('update:model', false);
    this.url = '';
    this.page = 1;
  }

  next() {
    if (this.page < this.pageCount) this.page += 1;
  }

  prev() {
    if (this.page > 1) this.page -= 1;
  }

  accept() {
    this.$emit('update:model', false);
  }

  get isDisabled() {
    if (this.page === this.pageCount && this.documentCheckbox === true) return false;
    return true;
  }
}
